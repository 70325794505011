<template>
  <div class="line-chart-warp" ref="lineChartWarpRef">
    <transition name="fade" :duration="1000">
      <div class="chart-tip theme_01" :style="{ left: lineChartLeft + 'px', top: lineChartTop + 'px' }"
        v-if="showChartTip && currentWeightScreen > targetWeightScreen && tipsData.length">
        {{ $t.ExpectedResult }}
      </div>
    </transition>
    <div class="line-chart" ref="lineChartRef">
      <!-- <v-chart :option="optionLine" style="width: 100%; height: 400px"></v-chart> -->
    </div>
  </div>
</template>

<script>
import echarts from "@/assets/js/line-echarts.min.js"
import webConfig from "@/config/web.config/index";
import moment from "moment";


export default {
  props: ["currentWeightScreen", "targetWeightScreen", "complatetargetDate", "weightUnit"],
  data() {
    return {
      lineChart: null,
      showChartTip: false,
      monthData: [],
      momentData: [],
      seriesData: [],
      tipsData: [],
      lineChartWarpWidth: 0,
    };
  },
  computed: {
    optionLine() { },
    lineChartLeft() {
      return ((this.lineChartWarpWidth - 20 * 2) / 4) * (this.tipsData.length - 1);
    },
    lineChartTop() {
      return this.currentWeightScreen > this.targetWeightScreen
        ? (this.tipsData.length - 1) * 30
        : 135 - (this.tipsData.length - 1) * 30;
    },
  },
  mounted() {

    this.initChart();
    window.addEventListener("resize", this.handleResize);
    this.getChartWarpWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  //事件处理
  methods: {
    // 图形初始化
    initChart() {
      this.lineChart = echarts.init(this.$refs.lineChartRef);
      this.getChartOptionConfig();
      let option = {
        grid: {
          top: this.currentWeightScreen == this.targetWeightScreen ? 120 : (this.currentWeightScreen > this.targetWeightScreen ? 90 : 35),
          left: 30,
          right: 30,
          bottom: 40,
        },
        xAxis: {
          type: "category",
          data: this.monthData,
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          lineStyle: {
            fontSize: 20,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontWeight: 400,
            fontSize: 12, // 日期字体大小为14
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            type: "line",
            smooth: true,
            zlevel: 9,
            symbolSize: 20,
            symbol: "circle",
            lineStyle: {
              width: 10,
              shadowColor: "rgba(0, 0, 0, 0.2)",
              shadowBlur: 10,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(232, 214, 90, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(107, 207, 184, 1)",
                  },
                ],
                global: false,
              },
            },
            areaStyle: {
              opacity: 0.1,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(232, 214, 90, 1)",
                  },
                  {
                    offset: 1,
                    color: "#56bb9a",
                  },
                ],
                global: false,
              },
            },
            markLine: {
              silent: false,
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: "#eee",
                type: "dashed",
              },
              data: [
                { xAxis: 1 },
                { xAxis: 2 },
                { xAxis: 3 },
                { xAxis: 4 },
                { xAxis: 5 },
              ],
            },

            markPoint: {
              symbol: "image://" + require("@/assets/image/chart_bg.png"),
              symbolSize: [60, 28],
              symbolOffset: [0, -34],
              silent: true,
              itemStyle: {
                color: "#333",
              },
              label: {
                show: true,
                fontSize: 12,
                fontWeight: 600,
                lineHeight: 15,
                position: "insideTop",
                textBorderColor: "#fff",
              },
              animationEasing: "linear",
              animationDelay: function (idx) {
                // 越往后的数据延迟越大
                return idx * 300;
              },
              animationDuration: 300,
              data: this.tipsData,
            },
            data: this.seriesData,
          },
        ],
      };
      this.lineChart.setOption(option);
      setTimeout(() => {
        this.showChartTip = true;
      }, 400 * (this.tipsData.length - 1));
    },
    // 通过体重变化获取日期坐标
    getChartOptionConfig() {
      if (this.currentWeightScreen && this.targetWeightScreen) {
        //体重未发生变化
        if (this.currentWeightScreen == this.targetWeightScreen) {
          this.momentData = [moment(new Date()), moment(new Date())];
        }
        // }体重发生变化
        else {
          let weightDifference = this.targetWeightScreen - this.currentWeightScreen;
          weightDifference = (weightDifference > 0 ? weightDifference : -weightDifference);
          let complateDate = this.$utils.getAnyDay(
            Math.round(weightDifference * 7),
            "yyyy-MM-DD"
          );
          let startDate = moment(new Date());
          let endDate = moment(complateDate);
          let monthsDiff = endDate.diff(startDate, "months"); //月份差异
          if (monthsDiff < 5) {
            this.getMonthConfig(startDate, 5, "nextMonth");
          } else {
            this.getMonthConfig(startDate, 2, "nextMonth");
            this.getMonthConfig(endDate, 2, "lastMonth");
          }
        }
        this.getServiceCongig();
      }
    },
    //通过日期获取体重变化
    getServiceCongig() {
      if (this.momentData.length) {
        let currentDate = moment(new Date());
        this.momentData.forEach((item, index) => {
          const daysDiff = item.diff(currentDate, "days"); //计算差距天数
          const weightDiff = Math.round(daysDiff / 7);
          switch (true) {
            case this.currentWeightScreen == this.targetWeightScreen:
              this.getEqualConfig(); //体重相等
              break;
            case this.currentWeightScreen > this.targetWeightScreen:
              this.getReduceConfig(weightDiff, index); //体重减少
              break;
            case this.currentWeightScreen < this.targetWeightScreen:
              this.getIncreaseConfig(weightDiff, index); //体重增加
              break;
          }
        });
      }
    },
    //体重增加
    getIncreaseConfig(weightDiff, index) {
      const coordinate = [25, 32, 45, 75, 90]; //固定坐标
      const currentMonthWeight = this.currentWeightScreen + weightDiff;
      let isComplate = false; //是否完成目标

      if (index > 0) {
        if (this.targetWeightScreen <= this.seriesData[index - 1].weight) {
          isComplate = true;
        }
      }
      // 体重变化不超过一个月 按下一个月展示tips
      if (!isComplate) {
        let tipValue =
          (index == coordinate.length - 1
            ? this.targetWeightScreen
            : currentMonthWeight < this.targetWeightScreen
              ? currentMonthWeight
              : this.targetWeightScreen);
        tipValue = (this.weightUnit == webConfig.weightKgUnitNumber ? this.$utils.formatNumber(tipValue) + 'KG' : this.$utils.formatNumber(this.$utils.kgToLbs(tipValue)) + 'LBS')
        this.tipsData.push({
          name: index + 1,
          xAxis: index,
          yAxis: coordinate[index],
          value: tipValue,
        });
      }
      this.seriesData.push({
        value: coordinate[index],
        weight: currentMonthWeight,
        itemStyle: {
          color: "#fff",
          borderWidth: 5,
          borderColor:
            index == this.momentData.length - 1
              ? "rgba(107, 207, 184, 1)"
              : isComplate
                ? "rgba(86, 86, 86, 0.1)"
                : "rgba(232, 214, 90, 1)",
        },
      });
    },
    //体重减少
    getReduceConfig(weightDiff, index) {
      const coordinate = [90, 80, 65, 35, 25]; //固定坐标
      const currentMonthWeight = this.currentWeightScreen - weightDiff;
      let isComplate = false; //是否完成目标

      if (index > 0) {
        if (this.targetWeightScreen >= this.seriesData[index - 1].weight) {
          isComplate = true;
        }
      }
      // 体重变化不超过一个月 按下一个月展示tips
      if (!isComplate) {
        let tipValue =
          (index == coordinate.length - 1
            ? this.targetWeightScreen
            : currentMonthWeight > this.targetWeightScreen
              ? currentMonthWeight
              : this.targetWeightScreen);
        tipValue = (this.weightUnit == webConfig.weightKgUnitNumber ? this.$utils.formatNumber(tipValue) + 'KG' : this.$utils.formatNumber(this.$utils.kgToLbs(tipValue)) + 'LBS')
        this.tipsData.push({
          name: index + 1,
          xAxis: index,
          yAxis: coordinate[index],
          value: tipValue,
        });
      }
      this.seriesData.push({
        value: coordinate[index],
        weight: currentMonthWeight,
        itemStyle: {
          color: "#fff",
          borderWidth: 5,
          borderColor:
            index == this.momentData.length - 1
              ? "rgba(107, 207, 184, 1)"
              : isComplate
                ? "rgba(86, 86, 86, 0.1)"
                : "rgba(232, 214, 90, 1)",
        },
      });
    },
    //体重相等
    getEqualConfig() {
      this.monthData = [moment(new Date()).format("MMM"), ""];
      this.tipsData = [
        {
          name: "1",
          xAxis: 0,
          yAxis: 80,
          value: this.targetWeightScreen + "KG",
        },
      ];
      this.seriesData = [
        {
          value: 60,
          itemStyle: {
            color: "#fff",
            borderWidth: 5,
            borderColor: "rgba(232, 214, 90, 1)",
          },
        },
        {
          value: 60,
          itemStyle: {
            color: "#fff",
            borderWidth: 5,
            borderColor: "rgba(107, 207, 184, 1)",
          },
        },
      ];
    },
    //循环获取日期
    getMonthConfig(date, n, method = "lastMonth") {

      // method：1.lastMonth：往上取月份 2.nextMonth:往下取月份
      if (method == "lastMonth") {
        for (let i = n; i >= 0; i--) {
          const currentDate = moment(date).subtract(i, "months");
          this.monthData.push(moment(currentDate).format("MMM"));
          this.momentData.push(currentDate);
        }
      } else {
        for (let i = 0; i < n; i++) {
          const currentDate = moment(date).add(i, "months");
          this.monthData.push(moment(currentDate).format("MMM"));
          this.momentData.push(currentDate);
        }
      }
    },
    // 获取chart容器宽度
    getChartWarpWidth() {
      if (this.$refs.lineChartWarpRef) {
        if (this.tipsData.length > 3) {
          this.lineChartWarpWidth = this.$refs.lineChartWarpRef.offsetWidth - 20;
        } else {
          this.lineChartWarpWidth = this.$refs.lineChartWarpRef.offsetWidth;
        }
      }
    },
    handleResize() {
      this.getChartWarpWidth();
      if (this.lineChart) {
        this.lineChart.resize();
      }
    },
  },
};
</script>

<style lang="scss">
.line-chart-warp {
  position: relative;
  height: 100%;

  .chart-tip {
    position: absolute;
    width: 60px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    background: #fff;
    z-index: 999;
  }

  .line-chart {
    width: 100%;
    height: 100%;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s;
}

@media (max-width: 576px) {}
</style>
